<!-- 文件中文名: 新闻公告 -->
<template>
  <div class="News app-container" style="text-align: left">
    <el-form :inline="true" :model="listQuery" size="small">
      <!-- 关键词 -->
      <el-form-item :label="$t('label.keyword') + ':'">
        <el-input v-model.trim="listQuery.title" style="width:140px;"/>
      </el-form-item>
      <el-button :loading="btnLoading" icon="el-icon-search" size="small" type="primary" @click="handleFilter">
        {{ $t('function.menu.search') }}
      </el-button>
    </el-form>
    <el-table
        v-loading="tableLoading"
        :data="list"
        border
        fit
        size="mini"
        stripe
        style="width: 100%"
    >
      <el-table-column :label="$t('sysOperationLog.moduleName')" align="center" header-align="center">
        <template v-slot="{row}">
          <el-button :title="$t('button.view')" circle icon="el-icon-search" size="mini" @click="viewTableBtn(row)"/>
        </template>
      </el-table-column>
      <!-- 标题 -->
      <el-table-column :label="$t('icInformation.title')" align="center" prop="title">
        <template v-slot="{row}">
          <span v-if="row.newType === 'new'" style="color: red;margin-right: 5px;font-size: 16px">New!</span><span>{{row.title}}</span>
        </template>
      </el-table-column>
      <!-- 发布时间 -->
      <el-table-column :label="$t('busi.message.issuetime')" align="center" prop="checkTime"/>
    </el-table>
    <pagination :limit.sync="listQuery.limit" :page.sync="listQuery.page" :total="total" @pagination="getList"/>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import {icAnnouncesList1} from "@/api/rus/api";

export default {
  name: 'News',
  directives: {},
  components: {Pagination},
  data() {
    return {
      btnLoading: false,
      tableLoading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10
      }
      // initDict: {
      //   status: 'fi_ac_input_status_cn'
      // }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      this.btnLoading = true
      icAnnouncesList1({...this.listQuery}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.btnLoading = false
        this.tableLoading = false
      }).catch(err => {
        console.warn(err)
        this.btnLoading = false
        this.tableLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    viewTableBtn(row) {
      this.$router.push({path: '/news-messages/news-details', query: {item: JSON.stringify(row)}})
    }
    }
  }

</script>

<style scoped>

</style>
